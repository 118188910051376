import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { closeSnackbar, enqueueSnackbar, removeSnackbar } from './actions';

export type NotificationsAction = ActionType<
  typeof enqueueSnackbar | typeof closeSnackbar | typeof removeSnackbar
>;

export type NotificationsState = Readonly<{
  notifications: any[];
}>;

const defaultState: NotificationsState = {
  notifications: [],
};

export default (state: NotificationsState = defaultState, action: AnyAction) => {
  const typedAction = action as NotificationsAction;

  switch (typedAction.type) {
    case getType(enqueueSnackbar):
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload.notification,
          },
        ],
      };

    case getType(closeSnackbar):
      return {
        ...state,
        notifications: state.notifications.map(
          notification =>
            notification.key === action.payload.key
              ? { ...notification, dismissed: true }
              : { ...notification },
        ),
      };

    case getType(removeSnackbar):
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.payload.key,
        ),
      };

    default:
      return state;
  }
};
