import { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from 'redux/Notifications';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class Notifier extends Component {
  displayed = [];

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    if (!newSnacks.length) {
      this.displayed = [];
      return false;
    }

    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (let i = 0; i < newSnacks.length; i += 1) {
      const newSnack = newSnacks[i];
      if (newSnack.dismissed) {
        this.props.closeSnackbar(newSnack.key);
        this.props.removeSnackbar(newSnack.key);
      }

      if (notExists) continue;
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnack.key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [], intl } = this.props;
    notifications.forEach(({ key, message, options = { action: [] } }) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(key)) return;
      // Display snackbar using notistack
      let messageToDisplay;
      try {
        messageToDisplay = intl.formatMessage(message);
      } catch (error) {
        messageToDisplay =
          message && message.id ? message.id : intl.formatMessage({ id: 'snackBar.unknown' });
      }
      this.props.enqueueSnackbar(messageToDisplay, {
        key,
        ...options,
        action: [
          ...options.action,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              this.props.closeSnackbar(key);
              this.props.removeSnackbar({ key });
            }}
          >
            <CloseIcon />
          </IconButton>,
        ],
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
          // Dispatch action to remove snackbar from redux store
          this.props.removeSnackbar({ key });
        },
      });
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(key);
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = dispatch => ({
  removeSnackbar: ({ key }) =>
    dispatch(
      removeSnackbar({
        key,
      }),
    ),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
  withSnackbar,
)(Notifier);
