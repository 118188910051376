import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import passwordValidator from 'password-validator';
import AuthTokenStorer from 'services/authentication/AuthTokenStorer';

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

const schema = new passwordValidator()
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .letters()
  .has()
  .digits()
  .has()
  .lowercase()
  .has()
  .not()
  .spaces();

export default class AuthenticationService {
  /**
   * Log the user in using Cognito
   * @param userEmail
   * @param userPassword
   */
  static login(userEmail, userPassword) {
    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: userEmail.toLowerCase(),
        Password: userPassword,
      };

      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const userData = {
        Username: userEmail,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: resolve,
        onFailure: err => {
          if (err.code === 'UserNotConfirmedException') {
            return resolve({ emailNotConfirmed: userEmail });
          }
          reject(err.code);
        },
      });
    });
  }

  static loginWithGoogle() {
    return global.gapi && global.gapi.auth2 && global.gapi.auth2.getAuthInstance().signIn();
  }

  /**
   * Sign up the user on Cognito
   * @param userEmail
   * @param userPassword
   */
  static signUp(userEmail, displayName, userPassword) {
    return new Promise((resolve, reject) => {
      const displayNameData = {
        Name: 'name',
        Value: displayName,
      };

      userPool.signUp(
        userEmail.toLowerCase(),
        userPassword,
        [new CognitoUserAttribute(displayNameData)],
        null,
        err => {
          if (err) {
            return reject(err.code);
          }

          return resolve({ email: userEmail });
        },
      );
    });
  }

  /**
   * Confirm the user signup on Cognito
   * Redirect to the login page on success
   * @param userEmail
   * @param confirmationCode
   */
  static confirmEmail(userEmail, confirmationCode) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: userEmail,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmRegistration(confirmationCode, true, err => {
        if (err) {
          return reject(err.code);
        }

        return resolve();
      });
    });
  }

  /**
   * Send confirmation email using Cognito
   * @param userEmail
   */
  static resendConfirmationEmail(userEmail) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: userEmail,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          return reject(err.code);
        }

        return resolve();
      });
    });
  }

  /**
   * Get the current cognito user
   */
  static getCognitoCurrentUser() {
    return userPool.getCurrentUser();
  }

  /**
   * Get the current google user
   */
  static getGoogleCurrentUser() {
    return (
      global.gapi && global.gapi.auth2 && global.gapi.auth2.getAuthInstance().currentUser.get()
    );
  }

  /**
   * Is user logged in with Google
   */
  static isGoogleLoggedIn() {
    return global.gapi && global.gapi.auth2 && global.gapi.auth2.getAuthInstance().isSignedIn.get();
  }

  /**
   * Is user logged in
   */
  static isLoggedIn() {
    const cognitoUser = AuthenticationService.getCognitoCurrentUser();

    const hasOpenSession =
      cognitoUser &&
      !!cognitoUser.getSession((err, session) => {
        return !err;
      });

    const localStorageToken = AuthTokenStorer.get();
    return hasOpenSession || AuthenticationService.isGoogleLoggedIn() || !!localStorageToken;
  }

  /**
   * Get the JWT from the current user
   */
  static getAuthenticationToken() {
    return new Promise(async (resolve, reject) => {
      const currentCognitoUser = AuthenticationService.getCognitoCurrentUser();

      if (currentCognitoUser) {
        return currentCognitoUser.getSession((err, session) => {
          if (err) {
            return reject(err);
          }

          return resolve(session.idToken.jwtToken);
        });
      }

      const googleAuthToken = await AuthenticationService.getGoogleAuthenticationToken();

      if (googleAuthToken) {
        return resolve(googleAuthToken);
      }

      return resolve(AuthTokenStorer.get());
    });
  }

  /**
   * Refresh the current user session
   */
  static refreshCognitoSession() {
    return new Promise((resolve, reject) => {
      const currentUser = AuthenticationService.getCognitoCurrentUser();

      if (!currentUser) {
        return resolve();
      }

      return currentUser.getSession((err, session) => {
        if (err) {
          return reject(err);
        }

        const RefreshToken = new CognitoRefreshToken({
          RefreshToken: session.refreshToken.token,
        });

        currentUser.refreshSession(RefreshToken, (err, session) => {
          if (err) {
            return reject(err);
          }

          return resolve(session);
        });
      });
    });
  }

  /**
   * Refresh the current user session
   */
  static refreshGoogleSession() {
    return (
      global.gapi &&
      global.gapi.auth2 &&
      global.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .reloadAuthResponse()
    );
  }

  /**
   * Return the google authentication token
   */
  static getGoogleAuthenticationToken() {
    return new Promise(resolve => {
      const isGoogleLoggedIn = AuthenticationService.isGoogleLoggedIn();

      if (isGoogleLoggedIn) {
        const currentGoogleUser = AuthenticationService.getGoogleCurrentUser();
        if (currentGoogleUser.getAuthResponse().expires_at < Date.now()) {
          return AuthenticationService.refreshGoogleSession().then(() => {
            return resolve(currentGoogleUser.getAuthResponse().id_token);
          });
        }

        return resolve(currentGoogleUser.getAuthResponse().id_token);
      }

      return resolve();
    });
  }

  /**
   * Log out from Cognito
   * Return to login afterwards
   */
  static logout() {
    const currentCognitoUser = AuthenticationService.getCognitoCurrentUser();

    if (currentCognitoUser) {
      currentCognitoUser.signOut();
    }

    global.gapi && global.gapi.auth2 && global.gapi.auth2.getAuthInstance().signOut();
    AuthTokenStorer.clear();
  }

  /**
   * Start forgot password flow
   */
  static resetPassword(email) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: () => resolve(email),
        onFailure: reject,
      });
    });
  }

  /**
   * Confirm new password
   */
  static confirmPassword(email, code, password) {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => resolve(email),
        onFailure: reject,
      });
    });
  }

  static isPasswordValid(password) {
    return schema.validate(password);
  }

  /**
   * Change password
   */
  static changePassword(email, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      const currentCognitoUser = AuthenticationService.getCognitoCurrentUser();
      currentCognitoUser.getSession(function(e) {
        if (e) {
          return reject(e);
        }
      });

      currentCognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    });
  }
}
