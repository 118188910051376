import { isAuthRoute, isPublicRoute } from 'components/root/utils';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { useDispatch, useSelector } from 'react-redux';
import { actions as currentUserActions } from 'redux/CurrentUser';
import AuthenticationService from 'services/authentication/AuthenticationService';
import CurrentUserClient from 'services/current-user/CurrentUserClient';
import enMessages from 'translations/en.json';
import { flattenMessages } from 'services/i18n/intl';
import useReactRouter from 'use-react-router';
import classes from './Root.module.scss';
import Notifier from 'components/organisms/Notifier';

const locales = {
  en: flattenMessages(enMessages),
};

addLocaleData([...en]);

const onRouteChange = async (pathname, history, currentUser, setCurrentUser) => {
  if (isAuthRoute(pathname)) {
    ReactGA.pageview(pathname);
    ReactGA.set({
      domain: currentUser && currentUser.email ? currentUser.email.split('@')[1] : 'unknown',
    });
    return;
  }

  const isLoggedIn = AuthenticationService.isLoggedIn();

  if (!isPublicRoute(pathname) && !isLoggedIn) {
    history.push('/login');
    return;
  }

  if (!isLoggedIn) {
    return;
  }

  if (!currentUser) {
    const currentUser = await CurrentUserClient.fetchCurrentUser();
    setCurrentUser(currentUser);
  }
};

const Root = ({ children }) => {
  const { location, history } = useReactRouter();
  const currentUser = useSelector(state => state.currentUser.currentUser);
  const dispatch = useDispatch();
  const setCurrentUser = currentUser =>
    dispatch(currentUserActions.setCurrentUser({ currentUser }));

  useEffect(
    () => {
      onRouteChange(location.pathname, history, currentUser, setCurrentUser);
    },
    [location.pathname],
  );

  return (
    <div className={classes.root}>
      <IntlProvider locale="en" messages={locales.en}>
        <React.Fragment>
          <Notifier />
          <div className={classes.pageContent}>{children}</div>
        </React.Fragment>
      </IntlProvider>
    </div>
  );
};

export default React.memo(Root);
