import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { DEPENDENCY_CARD_TYPE, EPIC_CARD_TYPE } from '../../constants/cardTypes';
import { Board } from '../Board';
import {
  deleteDependencyDependencyLink,
  deleteEpicDependencyLink,
  editDependencyDependencyLinks,
  editEpicDependencyLinks,
  showDependencyLinks,
  showEpicDependencyLinks,
  startLinkingDependency,
  startLinkingEpicToDependency,
  stopDependencyLinksDisplayMode,
  stopDependencyLinksEditMode,
  stopLinkingDependencyAndEpic,
  toggleLinkFullVisibility,
} from './actions';

export type DependencyLinkAction = { context: { board: Board } } & ActionType<
  | typeof toggleLinkFullVisibility
  | typeof showEpicDependencyLinks
  | typeof showDependencyLinks
  | typeof editEpicDependencyLinks
  | typeof editDependencyDependencyLinks
  | typeof stopDependencyLinksDisplayMode
  | typeof stopDependencyLinksEditMode
  | typeof deleteEpicDependencyLink
  | typeof deleteDependencyDependencyLink
  | typeof startLinkingEpicToDependency
  | typeof startLinkingDependency
  | typeof stopLinkingDependencyAndEpic
>;

export type DependencyLinkState = Readonly<{
  linkFullVisibility: boolean;
  linkOrigin: {
    isLinking: boolean;
    element?: any;
    type?: string;
    epicId?: string;
    epicZoneId?: string;
    epicDependencies?: string[];
    epicSprintId?: string;
    dependencyId?: string;
    dependencyZoneId?: string;
    dependencySprintId?: string;
    dependencyDueDate?: string;
    dependencyEpics?: string[];
    dependencyParentDependencies?: string[];
    dependencyChildDependencies?: string[];
  };
  linkDisplayOrigin: {
    displayMode: boolean;
    editionMode: boolean;
    epicId?: string;
    epicZoneId?: string;
    dependencyZoneId?: string;
    sprintId?: string;
    dependencyId?: string;
    epicIds?: string[];
    dependencyIds?: string[];
    dependencyParentIds?: string[];
    dependencyChildIds?: string[];
  };
}>;

const initialState: DependencyLinkState = {
  linkFullVisibility: false,
  linkOrigin: { isLinking: false },
  linkDisplayOrigin: {
    displayMode: false,
    editionMode: false,
  },
};

const reducer = (state: DependencyLinkState = initialState, action: AnyAction) => {
  const typedAction = action as DependencyLinkAction;

  switch (typedAction.type) {
    case getType(toggleLinkFullVisibility):
      return {
        ...state,
        linkFullVisibility: !state.linkFullVisibility,
      };
    case getType(startLinkingEpicToDependency):
      return {
        ...state,
        linkOrigin: {
          isLinking: true,
          element: typedAction.payload.element,
          type: EPIC_CARD_TYPE,
          epicSprintId: typedAction.payload.epicSprintId,
          epicZoneId: typedAction.payload.epicZoneId,
          epicId: typedAction.payload.epicId,
          epicDependencies: typedAction.payload.epicDependencies,
        },
      };
    case getType(startLinkingDependency):
      return {
        ...state,
        linkOrigin: {
          isLinking: true,
          element: typedAction.payload.element,
          type: DEPENDENCY_CARD_TYPE,
          dependencyId: typedAction.payload.dependencyId,
          dependencyZoneId: typedAction.payload.dependencyZoneId,
          dependencySprintId: typedAction.payload.dependencySprintId,
          dependencyDueDate: typedAction.payload.dependencyDueDate,
          dependencyEpics: typedAction.payload.dependencyEpics,
          dependencyParentDependencies: typedAction.payload.dependencyParentDependencies,
          dependencyChildDependencies: typedAction.payload.dependencyChildDependencies,
        },
      };
    case getType(stopLinkingDependencyAndEpic):
      return {
        ...state,
        linkOrigin: {
          ...state.linkOrigin,
          isLinking: false,
        },
      };
    case getType(showEpicDependencyLinks):
      return {
        ...state,
        linkDisplayOrigin: {
          displayMode: true,
          editionMode: false,
          originType: EPIC_CARD_TYPE,
          epicId: typedAction.payload.epicId,
          sprintId: typedAction.payload.sprintId,
          dependencyIds: typedAction.payload.dependencyIds,
        },
      };
    case getType(showDependencyLinks):
      return {
        ...state,
        linkDisplayOrigin: {
          displayMode: true,
          editionMode: false,
          originType: DEPENDENCY_CARD_TYPE,
          dependencyId: typedAction.payload.dependencyId,
          sprintId: typedAction.payload.sprintId,
          epicIds: typedAction.payload.epicIds,
          dependencyParentIds: typedAction.payload.dependencyParentIds,
          dependencyChildIds: typedAction.payload.dependencyChildIds,
        },
      };
    case getType(editEpicDependencyLinks):
      return {
        ...state,
        linkDisplayOrigin: {
          displayMode: false,
          editionMode: true,
          originType: EPIC_CARD_TYPE,
          epicId: typedAction.payload.epicId,
          epicZoneId: typedAction.payload.epicZoneId,
          sprintId: typedAction.payload.sprintId,
          dependencyIds: typedAction.payload.dependencyIds,
        },
      };
    case getType(editDependencyDependencyLinks):
      return {
        ...state,
        linkDisplayOrigin: {
          displayMode: false,
          editionMode: true,
          originType: DEPENDENCY_CARD_TYPE,
          dependencyId: typedAction.payload.dependencyId,
          dependencyZoneId: typedAction.payload.dependencyZoneId,
          sprintId: typedAction.payload.sprintId,
          dependencyChildIds: typedAction.payload.dependencyChildIds,
        },
      };
    case getType(deleteEpicDependencyLink):
      return {
        ...state,
        linkDisplayOrigin: {
          ...state.linkDisplayOrigin,
          dependencyIds:
            state.linkDisplayOrigin.dependencyIds &&
            state.linkDisplayOrigin.dependencyIds.filter(
              dependencyId => dependencyId !== typedAction.payload.dependencyId,
            ),
        },
      };
    case getType(deleteDependencyDependencyLink):
      return {
        ...state,
        linkDisplayOrigin: {
          ...state.linkDisplayOrigin,
          dependencyChildIds:
            state.linkDisplayOrigin.dependencyChildIds &&
            state.linkDisplayOrigin.dependencyChildIds.filter(
              dependencyId => dependencyId !== typedAction.payload.dependencyChildId,
            ),
        },
      };
    case getType(stopDependencyLinksDisplayMode):
      return {
        ...state,
        linkDisplayOrigin: {
          ...state.linkDisplayOrigin,
          displayMode: false,
        },
      };
    case getType(stopDependencyLinksEditMode):
      return {
        ...state,
        linkDisplayOrigin: {
          ...state.linkDisplayOrigin,
          editionMode: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
