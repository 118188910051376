import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import configureStore from './redux/store';
import './index.css';
import App from './App';
/*
if (process.env.NODE_ENV !== 'production') {
  const { whyDidYouUpdate } = require('why-did-you-update');
  whyDidYouUpdate(React);
}
*/
const history = createBrowserHistory();
const { store, persistor } = configureStore(history);

const rootEl = document.getElementById('root');

const launchApp = (rootEl, history, store, persistor) => {
  if (rootEl) {
    ReactDOM.render(<App history={history} store={store} persistor={persistor} />, rootEl);
  }

  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default; // eslint-disable-line
      if (rootEl) {
        ReactDOM.render(<NextApp history={history} store={store} persistor={persistor} />, rootEl);
      }
    });
  }
};

ReactGA.initialize(process.env.REACT_APP_GA_ID);
try {
  global.gapi.load('client:auth2', () => {
    global.gapi.auth2
      .init({ client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID })
      .then(() => {
        launchApp(rootEl, history, store, persistor);
      })
      .catch(error => {
        console.error(error);
        global.gapi = null;
        launchApp(rootEl, history, store, persistor);
      });
  });
} catch {
  global.gapi = null;
  launchApp(rootEl, history, store, persistor);
}
