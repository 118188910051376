import { RootState } from '../types';

export const selectSideMenuIsExpanded = (state: RootState) => {
  return state.display.sideMenuIsExpanded;
};

export const selectSideMenuIsOpen = (state: RootState) => state.display.sideMenuIsOpen;

export default {
  selectSideMenuIsExpanded,
  selectSideMenuIsOpen,
};
