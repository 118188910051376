import { createStandardAction } from 'typesafe-actions';

export const expandSideMenu = createStandardAction('Board/EXPAND_SIDE_MENU')();

export const closeSideMenu = createStandardAction('Board/CLOSE_SIDE_MENU')();

export const toggleMenu = createStandardAction('Board/TOGGLE_MENU')();

export const setEditAuthorization = createStandardAction('Board/SET_EDIT_AUTHORIZATION')<{
  editAuthorization: boolean;
}>();

export default {
  expandSideMenu,
  closeSideMenu,
  toggleMenu,
  setEditAuthorization,
};
