import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { closeSideMenu, expandSideMenu, toggleMenu, setEditAuthorization } from './actions';

export type DisplayAction = ActionType<
  typeof expandSideMenu | typeof closeSideMenu | typeof toggleMenu | typeof setEditAuthorization
>;

export type DisplayState = Readonly<{
  sideMenuIsExpanded: boolean;
  sideMenuIsOpen: boolean;
  editAuthorization: boolean;
}>;

const initialState: DisplayState = {
  sideMenuIsExpanded: false,
  sideMenuIsOpen: true,
  editAuthorization: false,
};

const reducer: Reducer<DisplayState> = (
  state: DisplayState = initialState,
  action: DisplayAction,
) => {
  switch (action.type) {
    case getType(expandSideMenu):
      return {
        ...state,
        sideMenuIsExpanded: true,
      };
    case getType(closeSideMenu):
      return {
        ...state,
        sideMenuIsExpanded: false,
      };
    case getType(toggleMenu):
      return {
        ...state,
        sideMenuIsOpen: !state.sideMenuIsOpen,
      };
    case getType(setEditAuthorization):
      return {
        ...state,
        editAuthorization: action.payload.editAuthorization,
      };
    default:
      return state;
  }
};

export default reducer;
