import gql from 'graphql-tag';
import apolloClient from 'ApolloClient';

const USER_QUERY = `
  id
  email
  displayName
  avatarUrl
  hasPassword
`;

export default class CurrentUserClient {
  /**
   * Fetch the current user
   */
  static async fetchCurrentUser() {
    const response = await apolloClient.query({
      query: gql`
        {
          me {
            ${USER_QUERY}
          }
        }
      `,
    });

    return response.data.me;
  }

  /**
   * Update profile
   */
  static async updateProfile(displayName, avatarUrl) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation UpdateProfile($displayName: String, $avatarUrl: String) {
          updateProfile(displayName: $displayName, avatarUrl: $avatarUrl) {
            ${USER_QUERY}
          }
        }
      `,
      variables: {
        displayName,
        avatarUrl,
      },
    });

    return response.data.updateProfile;
  }

  /**
   * Create password for google connect users
   */
  static async createPassword(userPassword) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation CreatePassword($userPassword: String) {
          createPassword(userPassword: $userPassword) {
            ${USER_QUERY}
          }
        }`,
      variables: {
        userPassword,
      },
    });

    return response.data.createPassword;
  }

  /**
   * Fetch avatar presigned updload url
   */
  static async fetchPresignedAvatarUploadUrl(fileType) {
    const response = await apolloClient.query({
      query: gql`
        {
          avatarPreSignedUrl(fileType: "${fileType}") {
            uploadUrl
            getUrl
          }
        }
      `,
    });

    return response.data.avatarPreSignedUrl;
  }
}
