import { ActionType, getType } from 'typesafe-actions';
import BoardService from '../../services/boards/BoardService';

import { AnyAction } from 'redux';
import {
  applyBoardChanges,
  initialiseActionStatus,
  moveDependency,
  moveEpic,
  moveZone,
  setBoard,
  setBoardVersion,
  setLoading,
  updateActionStatus,
} from './actions';
import { Board } from './types';

export type BoardAction = ActionType<
  | typeof setBoard
  | typeof setBoardVersion
  | typeof setLoading
  | typeof applyBoardChanges
  | typeof moveEpic
  | typeof moveDependency
  | typeof moveZone
  | typeof initialiseActionStatus
  | typeof updateActionStatus
>;

export type BoardState = Readonly<{
  board: Board | null;
  selectedVersionId: string | null;
  boardVersions: {
    [key: string]: Board;
  };
  currentCard: any;
  updates: any;
  loading: boolean;
}>;

const initialState: BoardState = {
  board: null,
  selectedVersionId: null,
  boardVersions: {},
  currentCard: null,
  updates: {},
  loading: true,
};

const reducer = (state: BoardState = initialState, action: AnyAction) => {
  const typedAction = action as BoardAction;

  switch (typedAction.type) {
    case getType(setBoard):
      return {
        ...state,
        board: typedAction.payload.board,
      };
    case getType(setBoardVersion):
      const newState = {
        ...state,
        selectedVersionId: typedAction.payload.id,
      };

      if (typedAction.payload.board) {
        newState.boardVersions = {
          ...state.boardVersions,
          [typedAction.payload.id]: typedAction.payload.board,
        };
      }
      return newState;
    case getType(setLoading):
      return {
        ...state,
        loading: typedAction.payload.loading,
      };
    case getType(applyBoardChanges):
      const board = BoardService.applyDiff(state.board, typedAction.payload.changes);
      board.currentSprintIndex = BoardService.computeCurrentSprintIndex(board);
      return {
        ...state,
        board,
      };
    case getType(moveEpic):
      if (state.board) {
        return {
          ...state,
          board: {
            ...state.board,
            sprints: BoardService.moveEpic(
              state.board.sprints,
              typedAction.payload.zoneId,
              typedAction.payload.sourceSprintId,
              typedAction.payload.destinationSprintId,
              typedAction.payload.sourceIndex,
              typedAction.payload.destinationIndex,
            ),
          },
        };
      }
      return state;
    case getType(moveDependency):
      if (state.board) {
        return {
          ...state,
          board: {
            ...state.board,
            sprints: BoardService.moveDependency(
              state.board.sprints,
              typedAction.payload.sourceZoneId,
              typedAction.payload.destinationZoneId,
              typedAction.payload.sourceSprintId,
              typedAction.payload.destinationSprintId,
              typedAction.payload.sourceIndex,
              typedAction.payload.destinationIndex,
            ),
          },
        };
      }
      return state;
    case getType(moveZone):
      if (state.board) {
        return {
          ...state,
          board: {
            ...state.board,
            zones: BoardService.moveZone(
              state.board.zones,
              typedAction.payload.sourceIndex,
              typedAction.payload.destinationIndex,
            ),
          },
        };
      }
      return state;

    case getType(initialiseActionStatus):
      return {
        ...state,
        updates: { ...state.updates, [action.payload.name]: { loading: true, error: null } },
      };
    case getType(updateActionStatus):
      return {
        ...state,
        updates: {
          ...state.updates,
          [action.payload.name]: { loading: action.payload.loading, error: action.payload.error },
        },
      };
    default:
      return state;
  }
};

export default reducer;
