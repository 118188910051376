const TOKEN_KEY = 'token';

export default class AuthTokenStorer {
  static set(token) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  static get() {
    return localStorage.getItem(TOKEN_KEY);
  }

  static clear() {
    localStorage.removeItem(TOKEN_KEY);
  }
}
