export const AUTH_ROUTES = [
  '/login',
  '/sign-up',
  '/confirm-email',
  '/forgot-password',
  '/confirm-password',
];
export const isAuthRoute = pathname => {
  for (const routename of AUTH_ROUTES) {
    if (pathname.startsWith(routename)) {
      return true;
    }
  }

  return false;
};

export const PUBLIC_ROUTES = ['/board', '/not-found'];
export const isPublicRoute = pathname => {
  for (const routename of PUBLIC_ROUTES) {
    if (pathname.startsWith(routename)) {
      return true;
    }
  }

  return false;
};
