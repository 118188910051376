import { createStandardAction } from 'typesafe-actions';
import { BoardConfig } from './types';

export const setBoardConfig = createStandardAction('Board/SET_BOARD_CONFIG')<{
  slugId: string;
  config: BoardConfig;
}>();

export const toggleZoneExpansion = createStandardAction('Board/TOGGLE_ZONE_EXPANSION')<{
  slugId: string;
  zoneId: string;
  zoneIsClosed: boolean;
}>();

export const toggleMilestoneSelection = createStandardAction('Board/TOGGLE_MILESTONE_SELECTION')<{
  slugId: string;
  milestoneId: string;
}>();

export default {
  setBoardConfig,
  toggleZoneExpansion,
  toggleMilestoneSelection,
};
