import gql from 'graphql-tag';
import apolloClient from 'ApolloClient';
import AuthenticationService from 'services/authentication/AuthenticationService';
import BoardService from 'services/boards/BoardService';
import { sessionId } from 'ApolloClient';

const FULL_BOARD_QUERY = `
  id
  name
  slug
  slugId
  startDate
  firstSprintNumber
  sprintDuration
  sprintDurationType
  currentSprintIndex
  isPublic
  editAuthorization
  checksum
  sprints {
    id
    capacity
    startDate
    endDate
    zoneEpicsList {
      zoneId
      epics {
        id
        name
        estimation
        hasProblem
        isDone
        dependencies
        epicTagId
      }
    }
    zoneDependenciesList {
      zoneId
      dependencies {
        id
        name
        estimation
        dueDate
        isDone
        ownerEmail
        dependencies
      }
    }
    assignees {
      email
      staffing
    }
  }
  members {
    id
    email
    displayName
    avatarUrl
  }
  milestones {
    id
    name
    date
  }
  epicTags {
    id
    name
    color
  }
  zones {
    id
    name
    type
    iconName
    ownerEmail
    overdueDependenciesCount
    estimationsVisibility
  }
`;

const BOARD_UPDATE_QUERY = `
  previousVersionChecksum
  checksum
  diff
  sessionId
`;

export const GET_BOARD_LIST_QUERY = gql`
  {
    boards {
      id
      name
      slug
      slugId
    }
  }
`;

export const GET_BOARD_HISTORY_QUERY = gql`
  query GetBoardHistory($boardId: String!) {
    history(boardId: $boardId) {
      items {
        id
        date
        editorEmails
        name
        items {
          id
          editorEmails
          date
          name
        }
      }
    }
  }
`;

export const GET_BOARD_VERSION_QUERY = gql`
  query GetBoardVersion($boardId: String!, $versionId: String!) {
    boardVersion(boardId: $boardId, versionId: $versionId) {
      ${FULL_BOARD_QUERY}
    }
  }
`;

export default class BoardClient {
  /**
   * Fetch the list of boards
   */
  static async fetchBoardList() {
    const response = await apolloClient.query({
      query: GET_BOARD_LIST_QUERY,
    });

    return response.data.boards;
  }

  /**
   * Create a new board
   */
  static async createBoard(name, startDate, firstSprintNumber, sprintDuration, sprintDurationType) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation CreateBoard(
          $name: String!
          $startDate: String!
          $firstSprintNumber: Int
          $sprintDuration: Int
          $sprintDurationType: String
        ) {
          addBoard(
            name: $name
            startDate: $startDate
            firstSprintNumber: $firstSprintNumber
            sprintDuration: $sprintDuration
            sprintDurationType: $sprintDurationType
          ) {
            id
            name
            slug
            slugId
          }
        }
      `,
      variables: {
        name,
        startDate,
        firstSprintNumber,
        sprintDuration,
        sprintDurationType,
      },
    });

    return response.data.addBoard;
  }

  /**
   * Update board settings
   */
  static async updateBoard(
    boardId,
    name,
    startDate,
    firstSprintNumber,
    sprintDuration,
    sprintDurationType,
    isPublic,
  ) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation UpdateBoard(
          $boardId: String!
          $name: String
          $startDate: String
          $firstSprintNumber: Int
          $sprintDuration: Int
          $sprintDurationType: String
          $isPublic: Boolean
        ) {
          updateBoard(
            boardId: $boardId
            name: $name
            startDate: $startDate
            firstSprintNumber: $firstSprintNumber
            sprintDuration: $sprintDuration
            sprintDurationType: $sprintDurationType
            isPublic: $isPublic
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        name,
        startDate,
        firstSprintNumber,
        sprintDuration,
        sprintDurationType,
        isPublic,
      },
    });

    return {
      changes: JSON.parse(response.data.updateBoard.diff),
      previousVersionChecksum: response.data.updateBoard.previousVersionChecksum,
      checksum: response.data.updateBoard.checksum,
    };
  }

  /**
   * Duplicate board
   */
  static async duplicateBoard(originalBoardId, nameOfDuplicatedBoard, keepMembers) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation DuplicateBoard(
          $originalBoardId: String!
          $nameOfDuplicatedBoard: String!
          $keepMembers: Boolean!
        ) {
          duplicateBoard(
            originalBoardId: $originalBoardId
            nameOfDuplicatedBoard: $nameOfDuplicatedBoard
            keepMembers: $keepMembers
          ) {
            id
            name
            slug
            slugId
          }
        }
      `,
      variables: {
        originalBoardId,
        nameOfDuplicatedBoard,
        keepMembers,
      },
    });
    return response.data.duplicateBoard;
  }

  /**
   * Delete board
   */
  static async deleteBoard(boardId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation DeleteBoard($boardId: String!) {
          removeBoard(boardId: $boardId)
        }
      `,
      variables: {
        boardId,
      },
    });
    return response.data.removeBoard;
  }

  /**
   * Fetch a board with its slugs
   */
  static async fetchBoard(slugId) {
    const response = await apolloClient.query({
      query: gql`
        query GetBoard($slugId: String!) {
           board(slugId: $slugId) {
            ${FULL_BOARD_QUERY}
           }
        }

      `,
      variables: {
        slugId,
      },
    });

    if (response.errors) {
      return { board: null, code: response.errors[0].extensions.code };
    }
    return {
      board: BoardService.hydrateDependenciesWithParents(response.data.board),
      code: 'OK',
    }; // TODO: put this logic in backend
  }

  /**
   * Fetch a board version
   */
  static async fetchBoardVersion(boardId, versionId) {
    const response = await apolloClient.query({
      query: GET_BOARD_VERSION_QUERY,
      variables: {
        boardId,
        versionId,
      },
    });

    return response.data.boardVersion;
  }

  /**
    Revert last change on board
   */
  static async revertLastChange(slugId) {
    const response = await apolloClient.mutate({
      mutation: gql`
      mutation ReverLastChange($slugId: String!) {
        revertLastChange(slugId: $slugId) {
          ${BOARD_UPDATE_QUERY}
        }
      }
    `,
      variables: {
        slugId,
      },
    });

    return {
      changes: response.data.revertLastChange && JSON.parse(response.data.revertLastChange.diff),
      previousVersionChecksum:
        response.data.revertLastChange && response.data.revertLastChange.previousVersionChecksum,
      checksum: response.data.revertLastChange && response.data.revertLastChange.checksum,
    };
  }

  /**
   * Update BoardName
   */
  static async editBoardName(boardId, name) {
    const response = await apolloClient.mutate({
      mutation: gql`
      mutation EditBoardName($boardId: String!, $name: String!) {
        editBoardName(boardId: $boardId, name: $name) {
          ${BOARD_UPDATE_QUERY}
        }
      }
    `,
      variables: {
        boardId,
        name,
      },
    });

    return {
      changes: JSON.parse(response.data.editBoardName.diff),
      previousVersionChecksum: response.data.editBoardName.previousVersionChecksum,
      checksum: response.data.editBoardName.checksum,
    };
  }

  /**
   * Update BoardHistoryItemName
   */
  static async editBoardHistoryItemName(boardId, historyItemId, name) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation EditBoardHistoryItemName(
          $boardId: String!
          $historyItemId: String!
          $name: String!
        ) {
          editBoardHistoryItemName(boardId: $boardId, historyItemId: $historyItemId, name: $name) {
            id
            date
            name
          }
        }
      `,
      variables: {
        boardId,
        historyItemId,
        name,
      },
    });

    return response.data.editBoardHistoryItemName;
  }

  /**
   * Add a new sprint
   */
  static async addSprint(boardId, sprintIndex) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation AddSprint($boardId: String!, $sprintIndex: Int) {
          addSprint(boardId: $boardId, sprintIndex: $sprintIndex) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintIndex,
      },
    });

    return {
      changes: JSON.parse(response.data.addSprint.diff),
      previousVersionChecksum: response.data.addSprint.previousVersionChecksum,
      checksum: response.data.addSprint.checksum,
    };
  }

  /**
   * Remove sprint
   */
  static async removeSprint(boardId, sprintId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation RemoveSprint($boardId: String!, $sprintId: String!) {
          removeSprint(boardId: $boardId, sprintId: $sprintId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
      },
    });

    return {
      changes: JSON.parse(response.data.removeSprint.diff),
      previousVersionChecksum: response.data.removeSprint.previousVersionChecksum,
      checksum: response.data.removeSprint.checksum,
    };
  }

  /**
   * Add a new epic tag
   */
  static async addEpicTag(boardId, name, color) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation AddEpicTag($boardId: String!, $name: String!, $color: String!) {
          addEpicTag(boardId: $boardId, name: $name, color: $color) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        name,
        color,
      },
    });

    return {
      changes: JSON.parse(response.data.addEpicTag.diff),
      previousVersionChecksum: response.data.addEpicTag.previousVersionChecksum,
      checksum: response.data.addEpicTag.checksum,
    };
  }

  /**
   * Update an epic tag
   */
  static async updateEpicTag(boardId, epicTagId, name, color) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation UpdateEpicTag($boardId: String!, $epicTagId: String!, $name: String!, $color: String!) {
            updateEpicTag(boardId: $boardId, epicTagId: $epicTagId, name: $name, color: $color) {
              ${BOARD_UPDATE_QUERY}
            }
        }
      `,
      variables: {
        boardId,
        epicTagId,
        name,
        color,
      },
    });
    return {
      changes: JSON.parse(response.data.updateEpicTag.diff),
      previousVersionChecksum: response.data.updateEpicTag.previousVersionChecksum,
      checksum: response.data.updateEpicTag.checksum,
    };
  }

  /**
   * Delete an epic tag
   */
  static async deleteEpicTag(boardId, epicTagId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation DeleteEpicTag($boardId: String!, $epicTagId: String!) {
            deleteEpicTag(boardId: $boardId, epicTagId: $epicTagId) {
              ${BOARD_UPDATE_QUERY}
            }
        }
      `,
      variables: {
        boardId,
        epicTagId,
      },
    });
    return {
      changes: JSON.parse(response.data.deleteEpicTag.diff),
      previousVersionChecksum: response.data.deleteEpicTag.previousVersionChecksum,
      checksum: response.data.deleteEpicTag.checksum,
    };
  }

  /**
   * Add a new member
   */
  static async addMember(boardId, email) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation AddMember($boardId: String!, $email: String!) {
          addMember(boardId: $boardId, email: $email) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        email,
      },
    });

    return {
      changes: JSON.parse(response.data.addMember.diff),
      previousVersionChecksum: response.data.addMember.previousVersionChecksum,
      checksum: response.data.addMember.checksum,
    };
  }

  /**
   * Remove a new member
   */
  static async removeMember(boardId, email) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation RemoveMember($boardId: String!, $email: String!) {
          removeMember(boardId: $boardId, email: $email) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        email,
      },
    });

    return {
      changes: JSON.parse(response.data.removeMember.diff),
      previousVersionChecksum: response.data.removeMember.previousVersionChecksum,
      checksum: response.data.removeMember.checksum,
    };
  }

  /**
   * Invite a new member to Splane
   */
  static async inviteMemberToSplane(boardId, email) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation InviteMemberToSplane($boardId: String!, $email: String!) {
          inviteMemberToSplane(boardId: $boardId, email: $email) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        email,
      },
    });

    return {
      changes: JSON.parse(response.data.inviteMemberToSplane.diff),
      previousVersionChecksum: response.data.inviteMemberToSplane.previousVersionChecksum,
      checksum: response.data.inviteMemberToSplane.checksum,
    };
  }

  /**
   * Add a new zone
   */
  static async addZone(boardId, type, name, icon, ownerEmail, estimationsVisibility) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation AddZone(
          $boardId: String!,
          $type: String!,
          $name: String!,
          $icon: String!,
          $ownerEmail: String,
          $estimationsVisibility: Boolean
        ) {
          addZone(
            boardId: $boardId,
            type: $type,
            name: $name,
            icon: $icon,
            ownerEmail: $ownerEmail,
            estimationsVisibility: $estimationsVisibility
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        type,
        name,
        icon,
        ownerEmail,
        estimationsVisibility,
      },
    });

    return {
      changes: JSON.parse(response.data.addZone.diff),
      previousVersionChecksum: response.data.addZone.previousVersionChecksum,
      checksum: response.data.addZone.checksum,
    };
  }

  /**
   * Edit a zone
   */
  static async editZone(boardId, zoneId, name, icon, ownerEmail, estimationsVisibility) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation EditZone(
          $boardId: String!,
          $zoneId: String!,
          $name: String!,
          $icon: String!,
          $ownerEmail: String,
          $estimationsVisibility: Boolean
        ) {
          updateZone(
            boardId: $boardId,
            zoneId: $zoneId,
            name: $name,
            iconName: $icon,
            ownerEmail: $ownerEmail,
            estimationsVisibility: $estimationsVisibility
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        zoneId,
        name,
        icon,
        ownerEmail,
        estimationsVisibility,
      },
    });

    return {
      changes: JSON.parse(response.data.updateZone.diff),
      previousVersionChecksum: response.data.updateZone.previousVersionChecksum,
      checksum: response.data.updateZone.checksum,
    };
  }

  /**
   * move zone
   */
  static async moveZone(boardId, sourceIndex, destinationIndex) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation ReorderZone(
          $boardId: String!
          $sourceIndex: Int!
          $destinationIndex: Int!
        ) {
          reorderZone(
            boardId: $boardId,
            sourceIndex: $sourceIndex
            destinationIndex: $destinationIndex
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sourceIndex,
        destinationIndex,
      },
    });

    return {
      changes: JSON.parse(response.data.reorderZone.diff),
      previousVersionChecksum: response.data.reorderZone.previousVersionChecksum,
      checksum: response.data.reorderZone.checksum,
    };
  }

  /**
   * Remove zone
   */
  static async removeZone(boardId, zoneId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation DeleteZone($boardId: String!, $zoneId: String!) {
          deleteZone(boardId: $boardId, zoneId: $zoneId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        zoneId,
      },
    });

    return {
      changes: JSON.parse(response.data.deleteZone.diff),
      previousVersionChecksum: response.data.deleteZone.previousVersionChecksum,
      checksum: response.data.deleteZone.checksum,
    };
  }

  /**
   * Add a new epic
   */
  static async addEpic(boardId, sprintId, zoneId, name, estimation, hasProblem, isDone, epicTagId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation AddEpic($boardId: String!, $sprintId: String!, $zoneId: String!, $name: String!,
                         $estimation: Float!, $hasProblem: Boolean!, $isDone: Boolean!
                         $epicTagId: String) {
          addEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, name: $name, estimation: $estimation, hasProblem: $hasProblem, isDone: $isDone, epicTagId: $epicTagId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        name,
        estimation,
        hasProblem,
        isDone,
        epicTagId: epicTagId || undefined,
      },
    });

    return {
      changes: JSON.parse(response.data.addEpic.diff),
      previousVersionChecksum: response.data.addEpic.previousVersionChecksum,
      checksum: response.data.addEpic.checksum,
    };
  }

  /**
   * Change sprint capacity
   */
  static async changeSprintCapacity(boardId, sprintId, capacity) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation ChangeSprintCapacity($boardId: String!, $sprintId: String!, $capacity: Int!) {
          setSprintCapacity(boardId: $boardId, sprintId: $sprintId, capacity: $capacity) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        capacity,
      },
    });

    return {
      changes: JSON.parse(response.data.setSprintCapacity.diff),
      previousVersionChecksum: response.data.setSprintCapacity.previousVersionChecksum,
      checksum: response.data.setSprintCapacity.checksum,
    };
  }

  /**
   * move an epic
   */
  static async moveEpic(boardId, zoneId, epicId, destinationSprintId, destinationIndex) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation MoveEpic(
          $boardId: String!
          $zoneId: String!
          $epicId: String!
          $destinationSprintId: String!
          $destinationIndex: Int!
        ) {
          moveEpic(
            boardId: $boardId
            zoneId: $zoneId
            epicId: $epicId
            destinationSprintId: $destinationSprintId
            destinationIndex: $destinationIndex
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        zoneId,
        epicId,
        destinationSprintId,
        destinationIndex,
      },
    });

    return {
      changes: JSON.parse(response.data.moveEpic.diff),
      previousVersionChecksum: response.data.moveEpic.previousVersionChecksum,
      checksum: response.data.moveEpic.checksum,
    };
  }

  /**
   * delete an epic
   */
  static async deleteEpic(boardId, sprintId, zoneId, epicId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation DeleteEpic(
          $boardId: String!
          $sprintId: String!
          $zoneId: String!
          $epicId: String!
        ) {
          deleteEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, epicId: $epicId) {
            diff
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        epicId,
      },
    });

    return {
      changes: JSON.parse(response.data.deleteEpic.diff),
      previousVersionChecksum: response.data.deleteEpic.previousVersionChecksum,
      checksum: response.data.deleteEpic.checksum,
    };
  }

  /**
   * Update epic
   */
  static async editEpic(
    boardId,
    sprintId,
    zoneId,
    epicId,
    name,
    estimation,
    hasProblem,
    isDone,
    epicTagId,
  ) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation updateEpic($boardId: String!, $sprintId: String!, $zoneId: String!, $epicId: String!, $name: String,
                            $estimation: Float, $hasProblem: Boolean, $isDone: Boolean, $epicTagId: String) {
          updateEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, epicId: $epicId, name: $name, estimation: $estimation, hasProblem: $hasProblem, isDone: $isDone, epicTagId: $epicTagId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        epicId,
        name,
        estimation,
        hasProblem,
        isDone,
        epicTagId: epicTagId || null,
      },
    });

    return {
      changes: JSON.parse(response.data.updateEpic.diff),
      previousVersionChecksum: response.data.updateEpic.previousVersionChecksum,
      checksum: response.data.updateEpic.checksum,
    };
  }

  /**
   * Add dependency to epic
   */
  static async addDependencyToEpic(boardId, sprintId, zoneId, epicId, dependencyId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation addDependency($boardId: String!, $sprintId: String!, $zoneId: String!, $epicId: String!, $dependencyId: String!) {
          linkDependencyToEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, epicId: $epicId, dependencyId: $dependencyId) {
              ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        epicId,
        dependencyId,
      },
    });

    return {
      changes: JSON.parse(response.data.linkDependencyToEpic.diff),
      previousVersionChecksum: response.data.linkDependencyToEpic.previousVersionChecksum,
      checksum: response.data.linkDependencyToEpic.checksum,
    };
  }

  /**
   * Add dependency to epic
   */
  static async removeDependencyFromEpic(boardId, sprintId, zoneId, epicId, dependencyId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation removeDependency($boardId: String!, $sprintId: String!, $zoneId: String!, $epicId: String!, $dependencyId: String!) {
          unlinkDependencyToEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, epicId: $epicId, dependencyId: $dependencyId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        epicId,
        dependencyId,
      },
    });

    return {
      changes: JSON.parse(response.data.unlinkDependencyToEpic.diff),
      previousVersionChecksum: response.data.unlinkDependencyToEpic.previousVersionChecksum,
      checksum: response.data.unlinkDependencyToEpic.checksum,
    };
  }

  /**
   * Add dependency to dependency
   */
  static async addDependencyToDependency(
    boardId,
    sprintId,
    zoneId,
    dependencyParentId,
    dependencyChildId,
  ) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation addDependencyToDependency($boardId: String!, $sprintId: String!, $zoneId: String!, $dependencyParentId: String!, $dependencyChildId: String!) {
          linkDependencyToDependency(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, dependencyParentId: $dependencyParentId, dependencyChildId: $dependencyChildId) {
              ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        dependencyParentId,
        dependencyChildId,
      },
    });

    return {
      changes: JSON.parse(response.data.linkDependencyToDependency.diff),
      previousVersionChecksum: response.data.linkDependencyToDependency.previousVersionChecksum,
      checksum: response.data.linkDependencyToDependency.checksum,
    };
  }

  /**
   * Remove dependency from dependency
   */
  static async removeDependencyFromDependency(
    boardId,
    sprintId,
    zoneId,
    dependencyParentId,
    dependencyChildId,
  ) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation removeDependencyFromDependency($boardId: String!, $sprintId: String!, $zoneId: String!, $dependencyParentId: String!, $dependencyChildId: String!) {
          unlinkDependencyToDependency(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, dependencyParentId: $dependencyParentId, dependencyChildId: $dependencyChildId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        dependencyParentId,
        dependencyChildId,
      },
    });

    return {
      changes: JSON.parse(response.data.unlinkDependencyToDependency.diff),
      previousVersionChecksum: response.data.unlinkDependencyToDependency.previousVersionChecksum,
      checksum: response.data.unlinkDependencyToDependency.checksum,
    };
  }

  /**
   * Set epic done / not done
   */
  static async toggleEpicDone(boardId, sprintId, zoneId, epicId, isDone) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation updateEpic($boardId: String!, $sprintId: String!, $zoneId: String!, $epicId: String!, $isDone: Boolean) {
          updateEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, epicId: $epicId, isDone: $isDone) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        epicId,
        isDone,
      },
    });

    return {
      changes: JSON.parse(response.data.updateEpic.diff),
      previousVersionChecksum: response.data.updateEpic.previousVersionChecksum,
      checksum: response.data.updateEpic.checksum,
    };
  }

  static async editEpicTagId(boardId, sprintId, zoneId, epicId, epicTagId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation updateEpic($boardId: String!, $sprintId: String!, $zoneId: String!, $epicId: String!, $epicTagId: String) {
          updateEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, epicId: $epicId, epicTagId: $epicTagId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        epicId,
        epicTagId: epicTagId || null,
      },
    });

    return {
      changes: JSON.parse(response.data.updateEpic.diff),
      previousVersionChecksum: response.data.updateEpic.previousVersionChecksum,
      checksum: response.data.updateEpic.checksum,
    };
  }

  /**
   * Set epic delayed / not delayed
   */
  static async toggleEpicDelayed(boardId, sprintId, zoneId, epicId, hasProblem) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation updateEpic($boardId: String!, $sprintId: String!, $zoneId: String!, $epicId: String!, $hasProblem: Boolean) {
          updateEpic(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, epicId: $epicId, hasProblem: $hasProblem) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        epicId,
        hasProblem,
      },
    });

    return {
      changes: JSON.parse(response.data.updateEpic.diff),
      previousVersionChecksum: response.data.updateEpic.previousVersionChecksum,
      checksum: response.data.updateEpic.checksum,
    };
  }

  /**
   * move a dependency
   */
  static async moveDependency(
    boardId,
    sourceZoneId,
    destinationZoneId,
    dependencyId,
    destinationSprintId,
    destinationIndex,
  ) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation MoveDependency(
          $boardId: String!
          $sourceZoneId: String!
          $destinationZoneId: String!
          $dependencyId: String!
          $destinationSprintId: String!
          $destinationIndex: Int!
        ) {
          moveDependency(
            boardId: $boardId,
            sourceZoneId: $sourceZoneId
            destinationZoneId: $destinationZoneId
            dependencyId: $dependencyId
            destinationSprintId: $destinationSprintId
            destinationIndex: $destinationIndex
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sourceZoneId,
        destinationZoneId,
        dependencyId,
        destinationSprintId,
        destinationIndex,
      },
    });

    return {
      changes: JSON.parse(response.data.moveDependency.diff),
      previousVersionChecksum: response.data.moveDependency.previousVersionChecksum,
      checksum: response.data.moveDependency.checksum,
    };
  }

  /**
   * Add a new dependency
   */
  static async addDependency(
    boardId,
    sprintId,
    zoneId,
    name,
    estimation,
    dueDate,
    isDone,
    ownerEmail,
  ) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation AddDependency(
          $boardId: String!,
          $sprintId: String!,
          $zoneId: String!,
          $name: String!,
          $estimation: Float!,
          $dueDate: String!,
          $isDone: Boolean!,
          $ownerEmail: String
        ) {
          addDependency(
            boardId: $boardId,
            sprintId: $sprintId,
            zoneId: $zoneId,
            name: $name,
            estimation: $estimation,
            dueDate: $dueDate,
            isDone: $isDone,
            ownerEmail: $ownerEmail
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        name,
        estimation,
        dueDate,
        isDone,
        ownerEmail: ownerEmail || undefined,
      },
    });

    return {
      changes: JSON.parse(response.data.addDependency.diff),
      previousVersionChecksum: response.data.addDependency.previousVersionChecksum,
      checksum: response.data.addDependency.checksum,
    };
  }

  /**
   * delete an dependency
   */
  static async deleteDependency(boardId, sprintId, zoneId, dependencyId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation DeleteDependency(
          $boardId: String!
          $sprintId: String!
          $zoneId: String!
          $dependencyId: String!
        ) {
          deleteDependency(
            boardId: $boardId
            sprintId: $sprintId
            zoneId: $zoneId
            dependencyId: $dependencyId
          ) {
            diff
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        dependencyId,
      },
    });

    return {
      changes: JSON.parse(response.data.deleteDependency.diff),
      previousVersionChecksum: response.data.deleteDependency.previousVersionChecksum,
      checksum: response.data.deleteDependency.checksum,
    };
  }

  /**
   * Update dependency
   */
  static async editDependency(
    boardId,
    sprintId,
    zoneId,
    dependencyId,
    name,
    estimation,
    dueDate,
    isDone,
    ownerEmail,
  ) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation updateDependency(
          $boardId: String!,
          $sprintId: String!,
          $zoneId: String!,
          $dependencyId: String!,
          $name: String,
          $estimation: Float,
          $dueDate: String,
          $isDone: Boolean,
          $ownerEmail: String
        ) {
          updateDependency(
            boardId: $boardId,
            sprintId: $sprintId,
            zoneId: $zoneId,
            dependencyId: $dependencyId,
            name: $name,
            estimation: $estimation,
            dueDate: $dueDate,
            isDone: $isDone,
            ownerEmail: $ownerEmail
          ) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        dependencyId,
        name,
        estimation,
        dueDate,
        isDone,
        ownerEmail: ownerEmail || undefined,
      },
    });

    return {
      changes: JSON.parse(response.data.updateDependency.diff),
      previousVersionChecksum: response.data.updateDependency.previousVersionChecksum,
      checksum: response.data.updateDependency.checksum,
    };
  }

  /**
   * Set dependency done / not done
   */
  static async toggleDependencyDone(boardId, sprintId, zoneId, dependencyId, isDone, ownerEmail) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation updateDependency(
          $boardId: String!,
          $sprintId: String!,
          $zoneId: String!,
          $dependencyId: String!,
          $isDone: Boolean
          $ownerEmail: String,
        ) {
          updateDependency(boardId: $boardId, sprintId: $sprintId, zoneId: $zoneId, dependencyId: $dependencyId, isDone: $isDone, ownerEmail: $ownerEmail) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        zoneId,
        dependencyId,
        isDone,
        ownerEmail,
      },
    });

    return {
      changes: JSON.parse(response.data.updateDependency.diff),
      previousVersionChecksum: response.data.updateDependency.previousVersionChecksum,
      checksum: response.data.updateDependency.checksum,
    };
  }

  /**
   * Toggle user assignation to sprint
   */
  static async toggleUserAssignation(boardId, sprintId, email) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation ToggleSprintUserAssignation($boardId: String!, $sprintId: String!, $email: String!) {
          toggleSprintUserAssignation(boardId: $boardId, sprintId: $sprintId, email: $email) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintId,
        email,
      },
    });

    return {
      changes: JSON.parse(response.data.toggleSprintUserAssignation.diff),
      previousVersionChecksum: response.data.toggleSprintUserAssignation.previousVersionChecksum,
      checksum: response.data.toggleSprintUserAssignation.checksum,
    };
  }

  static async applyStaffingToFollowingSprints(boardId, sprintIndex) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation ApplyStaffingToFollowingSprints($boardId: String!, $sprintIndex: Int!) {
          applyStaffingToFollowingSprints(boardId: $boardId, sprintIndex: $sprintIndex) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        sprintIndex,
      },
    });

    return {
      changes: JSON.parse(response.data.applyStaffingToFollowingSprints.diff),
      previousVersionChecksum:
        response.data.applyStaffingToFollowingSprints.previousVersionChecksum,
      checksum: response.data.applyStaffingToFollowingSprints.checksum,
    };
  }

  static async createMilestone(boardId, name, date) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation createMilestone($boardId: String!, $name: String!, $date: String!) {
          createMilestone(boardId: $boardId, name: $name, date: $date) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        name,
        date,
      },
    });

    return {
      changes: JSON.parse(response.data.createMilestone.diff),
      previousVersionChecksum: response.data.createMilestone.previousVersionChecksum,
      checksum: response.data.createMilestone.checksum,
    };
  }

  /**
   * Update milestone
   */
  static async updateMilestone(boardId, milestoneId, name, date) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation updateMilestone($boardId: String!, $milestoneId: String!, $name: String!,
                                $date: String!) {
          updateMilestone(boardId: $boardId, milestoneId: $milestoneId, name: $name, date: $date) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        milestoneId,
        name,
        date,
      },
    });

    return {
      changes: JSON.parse(response.data.updateMilestone.diff),
      previousVersionChecksum: response.data.updateMilestone.previousVersionChecksum,
      checksum: response.data.updateMilestone.checksum,
    };
  }

  /**
   * delete a milestone
   */
  static async deleteMilestone(boardId, milestoneId) {
    const response = await apolloClient.mutate({
      mutation: gql`
        mutation deleteMilestone($boardId: String!, $milestoneId: String!) {
          deleteMilestone(boardId: $boardId, milestoneId: $milestoneId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        milestoneId,
      },
    });

    return {
      changes: JSON.parse(response.data.deleteMilestone.diff),
      previousVersionChecksum: response.data.deleteMilestone.previousVersionChecksum,
      checksum: response.data.deleteMilestone.checksum,
    };
  }

  /**
   * Fetch a board with its slugs
   */
  static async subscribeToBoardUpdated(boardId, subscriptionCallback) {
    const authToken = await AuthenticationService.getAuthenticationToken();
    const observable = await apolloClient.subscribe({
      query: gql`
        subscription BoardUpdate($boardId: String!, $authToken: String!, $sessionId: String!) {
          boardUpdate(boardId: $boardId, authToken: $authToken, sessionId: $sessionId) {
            ${BOARD_UPDATE_QUERY}
          }
        }
      `,
      variables: {
        boardId,
        authToken,
        sessionId,
      },
    });

    return observable.subscribe({
      next: ({
        data: {
          boardUpdate: { diff, previousVersionChecksum, checksum, sessionId: receivedSessionId },
        },
      }) => {
        if (receivedSessionId !== sessionId) {
          subscriptionCallback({
            changes: JSON.parse(diff),
            previousVersionChecksum,
            checksum,
          });
        }
      },
    });
  }
}
