import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';

const Home = lazy(() => import('./components/pages/Home'));
const Login = lazy(() => import('./components/pages/Login'));
const SignUp = lazy(() => import('./components/pages/SignUp'));
const ConfirmEmail = lazy(() => import('./components/pages/ConfirmEmail'));
const Board = lazy(() => import('./components/pages/Board'));
const Profile = lazy(() => import('./components/pages/Profile'));
const ForgotPassword = lazy(() => import('./components/pages/ForgotPassword'));
const ConfirmPassword = lazy(() => import('./components/pages/ConfirmPassword'));
const NotFound = lazy(() => import('./components/pages/NotFound'));

const routes = () => (
  <Switch>
    <Suspense fallback={null}>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/sign-up/:email?" component={SignUp} />
      <Route path="/confirm-email/:email" component={ConfirmEmail} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/confirm-password/:email" component={ConfirmPassword} />
      <Route path="/profile" component={Profile} />
      <Route path="/board/:slugId/:slug?" component={Board} />
      <Route
        path="/redirect/board/:slugId/:slug"
        render={props => (
          <Redirect to={`/board/${props.match.params.slugId}/${props.match.params.slug}`} />
        )}
      />
      <Route path="/not-found" component={NotFound} />
    </Suspense>
  </Switch>
);

export default routes;
