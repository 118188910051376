import { createStandardAction } from 'typesafe-actions';

export const enqueueSnackbar = createStandardAction('Board/ENQUEUE_SNACKBAR')<{
  notification: any;
}>();

export const closeSnackbar = createStandardAction('Board/CLOSE_SNACKBAR')<{
  key: string;
}>();

export const removeSnackbar = createStandardAction('Board/REMOVE_SNACKBAR')<{
  key: string;
}>();

export default {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
};
