import { Reducer } from 'redux';
import { reducer as board } from './Board';
import { reducer as boardConfig } from './BoardConfig';
import { reducer as currentCard } from './CurrentCard';
import { reducer as currentUser } from './CurrentUser';
import { reducer as dependencyLink } from './DependencyLink';
import { reducer as display } from './Display';
import { reducer as notifications } from './Notifications';

import { RootAction, RootState } from './types';

export default function createReducer(asyncReducers: { router: Reducer }) {
  return (state: RootState, action: RootAction) => {
    const actionWithBoard = {
      context: { board: state && state.board && state.board.board },
      ...action,
    };

    return {
      router: asyncReducers.router(state && state.router, action),
      board: board(state && state.board, action),
      currentUser: currentUser(state && state.currentUser, action),
      boardConfig: boardConfig(state && state.boardConfig, actionWithBoard),
      currentCard: currentCard(state && state.currentCard, actionWithBoard),
      dependencyLink: dependencyLink(state && state.dependencyLink, actionWithBoard),
      display: display(state && state.display, action),
      notifications: notifications(state && state.notifications, action),
    };
  };
}
