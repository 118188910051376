import BoardService from '../../services/boards/BoardService';
import { RootState } from '../types';

export const selectGantt = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && BoardService.getGanttFromBoard(board);
};

export const selectDisplayedBoard = (state: RootState) => {
  if (!state.board) {
    return null;
  }

  if (state.board.selectedVersionId && state.board.boardVersions[state.board.selectedVersionId]) {
    return state.board.boardVersions[state.board.selectedVersionId];
  }

  return state.board.board;
};

export const selectBoardId = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.id;
};

export const selectBoardSlugId = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.slugId;
};

export const selectBoardFirstSprintNumber = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.firstSprintNumber;
};

export const selectBoardCurrentSprintIndex = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.currentSprintIndex;
};

export const selectBoardMembers = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.members;
};

export const selectBoardName = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.name;
};

export const selectBoardEpicTags = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.epicTags;
};

export const selectBoardStartDate = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.startDate;
};

export const selectBoardLastDate = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.sprints[board.sprints.length - 1].endDate;
};

export const selectNumberOfSprints = (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.sprints.length;
};

export const makeSelectZone = (zoneId: string) => (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.zones.find(zone => zone.id === zoneId);
};

export const makeSelectIsLastZone = (zoneId: string) => (state: RootState) => {
  const board = selectDisplayedBoard(state);
  return board && board.zones.findIndex(zone => zone.id === zoneId) === board.zones.length - 1;
};

export const selectActionStatus = (name: string) => (state: RootState) =>
  state.board && state.board.updates && state.board.updates[name];

export const selectActionLoadingStatus = (name: string) => (state: RootState) =>
  state.board &&
  state.board.updates &&
  state.board.updates[name] &&
  state.board.updates[name].loading;

export const selectActionErrorStatus = (name: string) => (state: RootState) =>
  state.board &&
  state.board.updates &&
  state.board.updates[name] &&
  state.board.updates[name].error;

export default {
  selectGantt,
  selectBoardId,
  selectBoardSlugId,
  selectBoardStartDate,
  selectBoardLastDate,
  selectNumberOfSprints,
  selectActionStatus,
  selectActionLoadingStatus,
  selectActionErrorStatus,
};
