import { applyChange } from 'deep-diff';
import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import ZONE_TYPES from 'constants/zoneTypes';
import { milestoneDateMarkerOffset, MILESTONE_HEIGHT } from 'constants/boardSizes';
import { DAY } from 'constants/sprintDurationType';
import moment from 'moment';

export default class BoardService {
  static applyDiff(board, changes) {
    if (!changes) {
      return board;
    }

    const newBoard = cloneDeep(board);
    changes.map(change => applyChange(newBoard, board, change));

    return BoardService.hydrateDependenciesWithParents(newBoard);
  }

  static getNewTagIdFromDiff(changes) {
    const fakeBoard = { epicTags: [] };
    const newFakeBoard = { epicTags: [] };
    changes.map(change => applyChange(newFakeBoard, fakeBoard, change));

    const createdTag = newFakeBoard.epicTags.find(tag => !!tag);
    return createdTag && createdTag.id;
  }

  static moveEpic(
    sprints,
    zoneId,
    sourceSprintId,
    destinationSprintId,
    sourceIndex,
    destinationIndex,
  ) {
    const sourceSprintIndex = sprints.findIndex(sprint => sprint.id === sourceSprintId);

    const sourceZoneEpicsIndex = sprints[sourceSprintIndex].zoneEpicsList.findIndex(
      zoneEpics => zoneEpics.zoneId === zoneId,
    );

    const newSprints = cloneDeep(sprints);

    if (sourceZoneEpicsIndex === -1) {
      return sprints;
    }

    if (sourceSprintId === destinationSprintId) {
      newSprints[sourceSprintIndex].zoneEpicsList[
        sourceZoneEpicsIndex
      ].epics = BoardService.reorderEpic(
        newSprints[sourceSprintIndex].zoneEpicsList[sourceZoneEpicsIndex].epics,
        sourceIndex,
        destinationIndex,
      );

      return newSprints;
    }

    const destinationSprintIndex = sprints.findIndex(sprint => sprint.id === destinationSprintId);

    const sourceClone = [...sprints[sourceSprintIndex].zoneEpicsList[sourceZoneEpicsIndex].epics];

    const destinationZoneEpicsIndex = sprints[destinationSprintIndex].zoneEpicsList.findIndex(
      zoneEpics => zoneEpics.zoneId === zoneId,
    );

    if (destinationZoneEpicsIndex === -1) {
      return sprints;
    }

    const destinationClone = [
      ...sprints[destinationSprintIndex].zoneEpicsList[destinationZoneEpicsIndex].epics,
    ];
    const [removed] = sourceClone.splice(sourceIndex, 1);

    destinationClone.splice(destinationIndex, 0, removed);

    newSprints[sourceSprintIndex].zoneEpicsList[sourceZoneEpicsIndex].epics = sourceClone;
    newSprints[destinationSprintIndex].zoneEpicsList[
      destinationZoneEpicsIndex
    ].epics = destinationClone;

    return newSprints;
  }

  static reorderEpic(previousEpics, sourceIndex, destinationIndex) {
    const result = [...previousEpics];
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    return result;
  }

  static moveDependency(
    sprints,
    sourceZoneId,
    destinationZoneId,
    sourceSprintId,
    destinationSprintId,
    sourceIndex,
    destinationIndex,
  ) {
    const sourceSprintIndex = sprints.findIndex(sprint => sprint.id === sourceSprintId);

    const sourceZoneDependenciesIndex = sprints[sourceSprintIndex].zoneDependenciesList.findIndex(
      zoneDependencies => zoneDependencies.zoneId === sourceZoneId,
    );

    const newSprints = cloneDeep(sprints);

    if (sourceZoneDependenciesIndex === -1) {
      return sprints;
    }

    if (sourceSprintId === destinationSprintId && sourceZoneId === destinationZoneId) {
      newSprints[sourceSprintIndex].zoneDependenciesList[
        sourceZoneDependenciesIndex
      ].dependencies = BoardService.reorderDependency(
        newSprints[sourceSprintIndex].zoneDependenciesList[sourceZoneDependenciesIndex]
          .dependencies,
        sourceIndex,
        destinationIndex,
      );

      return newSprints;
    }

    const destinationSprintIndex = sprints.findIndex(sprint => sprint.id === destinationSprintId);

    const sourceClone = [
      ...sprints[sourceSprintIndex].zoneDependenciesList[sourceZoneDependenciesIndex].dependencies,
    ];

    const destinationZoneDependenciesIndex = sprints[
      destinationSprintIndex
    ].zoneDependenciesList.findIndex(
      zoneDependencies => zoneDependencies.zoneId === destinationZoneId,
    );

    if (destinationZoneDependenciesIndex === -1) {
      return sprints;
    }

    const destinationClone = [
      ...sprints[destinationSprintIndex].zoneDependenciesList[destinationZoneDependenciesIndex]
        .dependencies,
    ];

    const [removed] = sourceClone.splice(sourceIndex, 1);

    destinationClone.splice(destinationIndex, 0, removed);

    newSprints[sourceSprintIndex].zoneDependenciesList[
      sourceZoneDependenciesIndex
    ].dependencies = sourceClone;
    newSprints[destinationSprintIndex].zoneDependenciesList[
      destinationZoneDependenciesIndex
    ].dependencies = destinationClone;

    return newSprints;
  }

  static reorderDependency(previousDependencies, sourceIndex, destinationIndex) {
    const result = [...previousDependencies];
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    return result;
  }

  static moveZone(zones, sourceIndex, destinationIndex) {
    const zone = zones.splice(sourceIndex, 1)[0];
    zones.splice(destinationIndex, 0, zone);
    return zones;
  }

  static computeMaxSprintPointSum(sprints, zoneId) {
    return Math.max(
      BoardService.computeMaxSprintWorkload(sprints, zoneId),
      BoardService.computeMaxSprintCapacity(sprints),
    );
  }

  static computeMaxSprintWorkload(sprints, zoneId) {
    return Math.max(...sprints.map(sprint => BoardService.computeSprintWorkload(sprint, zoneId)));
  }

  static computeMaxSprintCapacity(sprints) {
    return Math.max(...sprints.map(sprint => sprint.capacity));
  }

  static computeSprintWorkload(sprint, zoneId) {
    const zone = [...sprint.zoneEpicsList, ...sprint.zoneDependenciesList].find(
      zone => zone.zoneId === zoneId,
    );

    if (!zone) {
      return;
    }

    if (zone.epics) {
      return zone.epics.reduce((sum, epic) => sum + epic.estimation, 0);
    } else if (zone.dependencies) {
      return zone.dependencies.reduce(
        (sum, dependency) => sum + (dependency.estimation ? dependency.estimation : 0),
        0,
      );
    }

    return;
  }

  static computeMinEpicsEstimation(sprint, zoneId) {
    const zoneEpics = sprint.zoneEpicsList.find(zoneEpics => zoneEpics.zoneId === zoneId);

    if (!zoneEpics) {
      return;
    }

    return Math.min(...zoneEpics.epics.map(epic => epic.estimation));
  }

  static computeMinSprintsEstimation(sprints, zoneId) {
    return Math.min(
      ...sprints.map(sprint => BoardService.computeMinEpicsEstimation(sprint, zoneId)),
    );
  }

  static computeMaxSprintsEpicCount(sprints, zoneId) {
    return Math.max(
      ...sprints.map(sprint => {
        const zoneEpics = sprint.zoneEpicsList.find(zoneEpics => zoneEpics.zoneId === zoneId);

        if (!zoneEpics) {
          return null;
        }

        return zoneEpics.epics.length;
      }),
    );
  }

  static computeCurrentSprintIndex(board) {
    const now = moment();
    const boardStartDate = moment(board.startDate);

    if (boardStartDate.isAfter(now)) {
      return -1;
    }

    const daysDiff = now.diff(boardStartDate, 'days');

    return board.sprintDurationType === DAY
      ? daysDiff
      : Math.floor(daysDiff / (board.sprintDuration * 7));
  }

  static getAllEpicsLinkedToDependency(board, dependencyId) {
    return board.sprints.reduce((result, sprint) => {
      return [
        ...result,
        ...sprint.zoneEpicsList.reduce((zoneResult, zoneEpics) => {
          return [
            ...zoneResult,
            ...zoneEpics.epics.filter(epic => epic.dependencies.includes(dependencyId)),
          ];
        }, []),
      ];
    }, []);
  }

  // Check in all zoneDependencies
  static getAllDependenciesByIds(board, dependencyIds) {
    return board.sprints.reduce((result, sprint) => {
      return [
        ...result,
        ...sprint.zoneDependenciesList.reduce((zoneResult, zoneDependencies) => {
          return [
            ...zoneResult,
            ...zoneDependencies.dependencies.filter(dependency =>
              dependencyIds.includes(dependency.id),
            ),
          ];
        }, []),
      ];
    }, []);
  }

  // priority of a group of dependencies
  static getDependenciesGroupPriority(board, dependencyIds) {
    const now = moment().startOf('day');
    const dependencies = BoardService.getAllDependenciesByIds(board, dependencyIds);
    const isReady = dependencies.every(dependency => dependency.isDone);
    const hasProblem = dependencies.some(
      dependency => !dependency.isDone && moment(dependency.dueDate).isBefore(now),
    );

    if (isReady) return 0;
    return hasProblem ? 2 : 1;
  }

  static groupByZoneWithZoneIcon(board, dependencyIds) {
    function dependenciesByZonesOfSprint(sprint) {
      return sprint.zoneDependenciesList.reduce((dependenciesByZonesBySprint, zone) => {
        const matchingDependencyIds = zone.dependencies
          .filter(dependency => dependencyIds.includes(dependency.id))
          .map(dependency => dependency.id);
        return matchingDependencyIds.length
          ? {
              ...dependenciesByZonesBySprint,
              [zone.zoneId]: matchingDependencyIds,
            }
          : dependenciesByZonesBySprint;
      }, {});
    }

    const dependenciesByZones = board.sprints.reduce(
      (dependenciesByZones, sprint) =>
        mergeWith(dependenciesByZones, dependenciesByZonesOfSprint(sprint), (array1, array2) =>
          (array1 || []).concat(array2 || []),
        ),
      {},
    );

    return Object.keys(dependenciesByZones)
      .map(zoneId => ({
        id: zoneId,
        dependencyIds: dependenciesByZones[zoneId],
        iconName: BoardService.getIconNameOfZoneById(board.zones, zoneId),
        priority: BoardService.getDependenciesGroupPriority(board, dependenciesByZones[zoneId]),
      }))
      .sort((groupA, groupB) => groupB.priority - groupA.priority);
  }

  static getIconNameOfZoneById(zones, zoneId) {
    let zone = zones.find(z => z.id === zoneId);
    return zone ? zone.iconName : 'defaultDependencyIconName';
  }

  static hydrateDependenciesWithParents(board) {
    const dependenciesToEpicsMap = {};
    const dependenciesToDependenciesMap = {};

    const epicZones = board.zones.filter(zone => zone.type === ZONE_TYPES.EPIC);
    const dependencyZones = board.zones.filter(zone => zone.type === ZONE_TYPES.DEPENDENCY);

    board.sprints.forEach(sprint => {
      epicZones.forEach(zone => {
        const zoneEpics = sprint.zoneEpicsList.find(zoneEpics => zoneEpics.zoneId === zone.id);
        if (!zoneEpics) {
          return;
        }

        zoneEpics.epics.forEach(epic => {
          epic.dependencies.forEach(dependencyId => {
            const currentEpics = dependenciesToEpicsMap[dependencyId] || [];
            dependenciesToEpicsMap[dependencyId] = currentEpics.concat(epic.id);
          });
        });
      });

      dependencyZones.forEach(zone => {
        const zoneDependencies = sprint.zoneDependenciesList.find(
          zoneDependencies => zoneDependencies.zoneId === zone.id,
        );
        if (!zoneDependencies) {
          return;
        }

        zoneDependencies.dependencies.forEach(dependency => {
          dependency.dependencies.forEach(dependencyChildId => {
            const currentParentDependencies =
              dependenciesToDependenciesMap[dependencyChildId] || [];
            dependenciesToDependenciesMap[dependencyChildId] = currentParentDependencies.concat(
              dependency.id,
            );
          });
        });
      });
    });

    return {
      ...board,
      sprints: board.sprints.map(sprint => ({
        ...sprint,
        zoneDependenciesList: sprint.zoneDependenciesList.map(zoneDependencies => ({
          ...zoneDependencies,
          dependencies: zoneDependencies.dependencies.map(dependency => ({
            ...dependency,
            epics: dependenciesToEpicsMap[dependency.id] || [],
            parentDependencies: dependenciesToDependenciesMap[dependency.id] || [],
          })),
        })),
      })),
    };
  }

  static getPreviousSprintId(board, sprintId) {
    const index = board.sprints.findIndex(sprint => sprint.id === sprintId);
    if (index < 1) return null;
    return board.sprints[index - 1].id;
  }

  static getNextSprintId(board, sprintId) {
    const index = board.sprints.findIndex(sprint => sprint.id === sprintId);
    if (index === -1) return null;
    if (index + 1 === board.sprints.length) return null;
    return board.sprints[index + 1].id;
  }

  static calculateLittleMarkerPosition(milestone, boardStartDate, totalBoardWidth, totalBoardTime) {
    let milestoneBoardTime = (
      moment(milestone.date)
        .startOf('day')
        .add(12, 'hours') - moment(boardStartDate).startOf('day')
    ).valueOf();
    const markerComputedLeftPosition = (milestoneBoardTime / totalBoardTime) * totalBoardWidth;
    const markerPositionInWindow = Math.max(
      Math.min(markerComputedLeftPosition, totalBoardWidth),
      0,
    );

    return markerPositionInWindow;
  }

  static calculateMilestoneZoneHeight(actualMilestoneZoneHeight, milestoneTopPosition) {
    let milestoneZoneHeight = Math.max(
      actualMilestoneZoneHeight,
      MILESTONE_HEIGHT + milestoneTopPosition,
    );

    return milestoneZoneHeight;
  }

  static calculateMilestoneLeftPosition(markerPosition, totalBoardWidth, milestoneWidth) {
    let milestoneLeftPosition = markerPosition;
    let milestoneLeftPositionWithOffset = Math.max(
      milestoneLeftPosition - milestoneDateMarkerOffset,
      0,
    );

    const isMilestoneOutOnTheRight =
      milestoneLeftPositionWithOffset > totalBoardWidth - milestoneWidth;

    if (isMilestoneOutOnTheRight) {
      milestoneLeftPositionWithOffset =
        totalBoardWidth - milestoneWidth + milestoneDateMarkerOffset;
    }

    return milestoneLeftPositionWithOffset;
  }

  static calculateMilestoneTopPositionAndZoneHeight(
    milestonesPositions,
    milestoneLeftPositionWithOffset,
    milestoneWidth,
    milestoneZoneHeight,
  ) {
    let milestoneTopPosition = 0;
    let newMilestoneZoneHeight = milestoneZoneHeight;
    Object.keys(milestonesPositions).forEach(computedMilestoneId => {
      if (
        milestoneLeftPositionWithOffset >= milestonesPositions[computedMilestoneId].leftPosition &&
        milestoneLeftPositionWithOffset <=
          milestonesPositions[computedMilestoneId].leftPosition + milestoneWidth
      ) {
        milestoneTopPosition =
          milestonesPositions[computedMilestoneId].topPosition + MILESTONE_HEIGHT;
        newMilestoneZoneHeight = this.calculateMilestoneZoneHeight(
          milestoneZoneHeight,
          milestoneTopPosition,
        );
      }
    });

    return { milestoneTopPosition, newMilestoneZoneHeight };
  }

  static calculateMilestonePosition(
    milestonesPositions,
    markerPosition,
    totalBoardWidth,
    milestoneWidth,
    milestoneZoneHeight,
  ) {
    const milestoneLeftPosition = this.calculateMilestoneLeftPosition(
      markerPosition,
      totalBoardWidth,
      milestoneWidth,
    );
    const {
      milestoneTopPosition,
      newMilestoneZoneHeight,
    } = this.calculateMilestoneTopPositionAndZoneHeight(
      milestonesPositions,
      milestoneLeftPosition,
      milestoneWidth,
      milestoneZoneHeight,
    );

    return { milestoneLeftPosition, milestoneTopPosition, newMilestoneZoneHeight };
  }

  static calculateLittleMarkerHeight(milestonesPositions, milestoneZoneHeight) {
    let milestonesDateMarkerHeight = {};
    Object.keys(milestonesPositions).forEach(
      milestoneId =>
        (milestonesDateMarkerHeight[milestoneId] =
          2 +
          milestoneZoneHeight -
          MILESTONE_HEIGHT -
          milestonesPositions[milestoneId].topPosition),
    );

    return milestonesDateMarkerHeight;
  }

  static placeMilestoneZoneElements(
    milestones,
    boardStartDate,
    boardLastDate,
    totalBoardWidth,
    milestoneWidth,
  ) {
    let sortedMilestones = milestones
      .slice()
      .sort(
        (milestone, otherMilestone) =>
          moment(milestone.date).valueOf() - moment(otherMilestone.date).valueOf(),
      );

    let milestoneZoneHeight = MILESTONE_HEIGHT;
    const totalBoardTime = (
      moment(boardLastDate).endOf('day') - moment(boardStartDate).startOf('day')
    ).valueOf();
    const milestonesPositions = {};
    const milestonesMarkerLeftPositions = {};
    sortedMilestones.forEach(milestone => {
      const markerPosition = this.calculateLittleMarkerPosition(
        milestone,
        boardStartDate,
        totalBoardWidth,
        totalBoardTime,
      );
      const {
        milestoneLeftPosition,
        milestoneTopPosition,
        newMilestoneZoneHeight,
      } = this.calculateMilestonePosition(
        milestonesPositions,
        markerPosition,
        totalBoardWidth,
        milestoneWidth,
        milestoneZoneHeight,
      );

      milestoneZoneHeight = newMilestoneZoneHeight;
      milestonesPositions[milestone.id] = {
        leftPosition: milestoneLeftPosition,
        topPosition: milestoneTopPosition,
      };
      milestonesMarkerLeftPositions[milestone.id] = markerPosition - milestoneLeftPosition;
    });
    const milestonesDateMarkerHeight = this.calculateLittleMarkerHeight(
      milestonesPositions,
      milestoneZoneHeight,
    );

    return {
      milestonesPositions,
      milestonesMarkerLeftPositions,
      milestonesDateMarkerHeight,
      milestoneZoneHeight,
    };
  }

  static getEpicsForTag(board, tagId) {
    const tags = board.epicTags;
    if (!tags.map(tag => tag.id).includes(tagId)) {
      return [];
    }

    return board.sprints.reduce(
      (sprintAccumulator, sprint) => [
        ...sprintAccumulator,
        ...sprint.zoneEpicsList.reduce(
          (zoneAccumulator, zone) => [
            ...zoneAccumulator,
            ...zone.epics.filter(epic => epic.epicTagId === tagId),
          ],
          [],
        ),
      ],
      [],
    );
  }

  static getGanttFromBoard(board) {
    let tags = board.epicTags.map(tag => ({
      ...tag,
      startDate: '',
      endDate: '',
      numberEpicDone: 0,
      numberEpic: 0,
    }));
    let tagIndex = tags.map(tag => tag.id).reduce(
      (accumulator, tagId) => ({
        ...accumulator,
        [tagId]: tags.findIndex(tag => tag.id === tagId),
      }),
      {},
    );

    let currentSprintTags;
    board.sprints.forEach(sprint => {
      // get tags included in this sprint
      currentSprintTags = sprint.zoneEpicsList.reduce((accumulator, epicsList) => {
        return [
          ...accumulator,
          ...epicsList.epics
            .map(epic => {
              if (epic.epicTagId) {
                tags[tagIndex[epic.epicTagId]].numberEpic++;
                if (epic.isDone) {
                  tags[tagIndex[epic.epicTagId]].numberEpicDone++;
                }
              }
              return epic.epicTagId;
            })
            .filter(id => id !== '' && !accumulator.includes(id)),
        ];
      }, []);

      // update tags min/max-dates
      currentSprintTags.forEach(tagId => {
        if (
          tags[tagIndex[tagId]].startDate === '' ||
          tags[tagIndex[tagId]].startDate > sprint.startDate
        ) {
          tags[tagIndex[tagId]].startDate = sprint.startDate;
        }
        if (
          tags[tagIndex[tagId]].endDate === '' ||
          tags[tagIndex[tagId]].endDate < sprint.startDate
        ) {
          tags[tagIndex[tagId]].endDate = sprint.endDate;
        }
      });
    });

    //associate tags to lines
    tags = tags.filter(tag => tag.startDate !== '');
    tags.sort((tag1, tag2) => (tag1.startDate < tag2.startDate ? -1 : 1));
    let lignesEndDate = [];
    tags.forEach(tag => {
      tag.lineIndex = null;
      lignesEndDate.forEach((endDate, lineIndex) => {
        if (tag.lineIndex === null && endDate < tag.startDate) {
          tag.lineIndex = lineIndex;
          lignesEndDate[lineIndex] = tag.endDate;
        }
      });
      if (tag.lineIndex === null) {
        lignesEndDate.push(tag.endDate);
        tag.lineIndex = lignesEndDate.length - 1;
      }
    });
    return tags;
  }
}
