import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { actions } from 'redux/Display';
import { ActionType, getType } from 'typesafe-actions';
import BoardClient from '../../services/boards/BoardClient';
import {
  applyBoardChanges,
  callAPI as callAPIAction,
  fetchBoard as fetchBoardAction,
  fetchBoardVersion as fetchBoardVersionAction,
  initialiseActionStatus,
  setBoard,
  setBoardVersion,
  setLoading,
  subscriptionUpdateBoard as subscriptionUpdateBoardAction,
  updateActionStatus,
  updateBoard as updateBoardAction,
} from './actions';

export function* fetchBoard(action: ActionType<typeof fetchBoardAction>) {
  try {
    yield put(setLoading({ loading: true }));
    yield put(setBoard({ board: null }));
    const { board, code } = yield call(BoardClient.fetchBoard, action.payload.slugId);

    if (code !== 'OK') {
      // TODO: refactor error handling
      yield put(push('/not-found'));
    } else {
      yield put(setBoard({ board }));
      yield put(actions.setEditAuthorization({ editAuthorization: board.editAuthorization }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(setLoading({ loading: false }));
  }
}

export function* fetchBoardVersion(action: ActionType<typeof fetchBoardVersionAction>) {
  try {
    const boardVersions = yield select(state => state.board.boardVersions);

    if (!boardVersions[action.payload.versionId]) {
      const boardVersion = yield call(
        BoardClient.fetchBoardVersion,
        action.payload.boardId,
        action.payload.versionId,
      );

      if (boardVersion) {
        yield put(setBoardVersion({ id: action.payload.versionId, board: boardVersion }));
      }
    } else {
      yield put(setBoardVersion({ id: action.payload.versionId, board: null }));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateBoard(action: ActionType<typeof updateBoardAction>) {
  try {
    // save in store that this action is required
    yield put(initialiseActionStatus({ name: action.payload.name }));
    // call the API
    const boardChecksum = yield select(state => state.board.board.checksum);
    const boardSlugId = yield select(state => state.board.board.slugId);
    const { changes, previousVersionChecksum } = yield call(
      action.payload.fn,
      ...action.payload.args,
    );
    if (boardChecksum === previousVersionChecksum) {
      yield put(applyBoardChanges({ changes }));
    } else {
      const { board } = yield call(BoardClient.fetchBoard, boardSlugId);
      yield put(setBoard({ board }));
    }
    yield put(updateActionStatus({ name: action.payload.name, loading: false, error: '' }));
    if (action.payload.onSuccess) {
      action.payload.onSuccess(changes);
    }
    return changes;
  } catch (error) {
    yield put(updateActionStatus({ name: action.payload.name, loading: false, error: 'error' }));

    if (action.payload.onError) {
      action.payload.onError(error);
    }
    console.error('error : ', error);
  }
}

export function* callAPI(action: ActionType<typeof callAPIAction>) {
  try {
    // save in store that this action is requiered
    yield put(initialiseActionStatus({ name: action.payload.name }));
    // call the API
    const changes = yield call(action.payload.fn, ...action.payload.args);

    yield put(updateActionStatus({ name: action.payload.name, loading: false, error: '' }));
    if (action.payload.onSuccess) {
      action.payload.onSuccess(changes);
    }
    return changes;
  } catch (error) {
    yield put(updateActionStatus({ name: action.payload.name, loading: false, error: 'error' }));

    if (action.payload.onError) {
      action.payload.onError(error);
    }
    console.log('error : ', error);
  }
}

export function* subscriptionUpdateBoard(action: ActionType<typeof subscriptionUpdateBoardAction>) {
  const { previousVersionChecksum, changes } = action.payload.updates;
  const boardChecksum = yield select(state => state.board.board.checksum);
  const boardSlugId = yield select(state => state.board.board.slugId);

  if (boardChecksum === previousVersionChecksum) {
    yield put(applyBoardChanges({ changes }));
  } else {
    const { board } = yield call(BoardClient.fetchBoard, boardSlugId);
    yield put(setBoard({ board }));
  }
}

export default function* fetchBoardSaga() {
  yield takeEvery(getType(fetchBoardAction), fetchBoard);
  yield takeEvery(getType(fetchBoardVersionAction), fetchBoardVersion);
  yield takeEvery(getType(updateBoardAction), updateBoard);
  yield takeEvery(getType(subscriptionUpdateBoardAction), subscriptionUpdateBoard);
  yield takeEvery(getType(callAPIAction), callAPI);
}
