import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { setBoardConfig, toggleMilestoneSelection, toggleZoneExpansion } from './actions';
import { BoardConfig } from './types';

export type BoardConfigAction = ActionType<
  typeof setBoardConfig | typeof toggleZoneExpansion | typeof toggleMilestoneSelection
>;

export type BoardConfigState = Readonly<{
  config: {
    [key: string]: BoardConfig;
  };
}>;

const initialState: BoardConfigState = {
  config: {},
};

const reducer = (state: BoardConfigState = initialState, action: AnyAction) => {
  const typedAction = action as BoardConfigAction;

  switch (typedAction.type) {
    case getType(setBoardConfig):
      return {
        ...state,
        config: {
          ...state.config,
          [`boardConfig:${action.payload.slugId}`]: {
            ...state.config[`boardConfig:${action.payload.slugId}`],
            ...action.payload.config,
          },
        },
      };
    case getType(toggleZoneExpansion):
      const existingZonesIsClosed =
        state.config[`boardConfig:${action.payload.slugId}`] &&
        state.config[`boardConfig:${action.payload.slugId}`].zonesIsClosed
          ? state.config[`boardConfig:${action.payload.slugId}`].zonesIsClosed
          : {};
      return {
        ...state,
        config: {
          ...state.config,
          [`boardConfig:${action.payload.slugId}`]: {
            ...state.config[`boardConfig:${action.payload.slugId}`],
            zonesIsClosed: {
              ...existingZonesIsClosed,
              [action.payload.zoneId]: action.payload.zoneIsClosed,
            },
          },
        },
      };
    case getType(toggleMilestoneSelection):
      const existingMilestonesIsSelected =
        state.config[`boardConfig:${action.payload.slugId}`] &&
        state.config[`boardConfig:${action.payload.slugId}`].milestonesIsSelected
          ? state.config[`boardConfig:${action.payload.slugId}`].milestonesIsSelected
          : {};
      return {
        ...state,
        config: {
          ...state.config,
          [`boardConfig:${action.payload.slugId}`]: {
            ...state.config[`boardConfig:${action.payload.slugId}`],
            milestonesIsSelected: {
              ...existingMilestonesIsSelected,
              [action.payload.milestoneId]: !existingMilestonesIsSelected[
                action.payload.milestoneId
              ],
            },
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
