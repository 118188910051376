import { User } from 'redux/CurrentUser/types';
import { createStandardAction } from 'typesafe-actions';

export const setCurrentUser = createStandardAction('Board/SET_CURRENT_USER')<{
  currentUser: User;
}>();

export default {
  setCurrentUser,
};
