import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Router, Route } from 'react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import MomentUtils from '@date-io/moment';
import theme from 'theme';
import Routes from './routes';
import ApolloClient from './ApolloClient';
import Root from './components/root';
import moment from 'moment';
import 'moment/locale/en-gb';
import { SnackbarProvider } from 'notistack';

moment.locale('en-gb');

const RootComponentWithRoutes = () => (
  <Root>
    <Routes />
  </Root>
);

class App extends React.Component {
  render() {
    const { history, store, persistor } = this.props;

    return (
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={3}
        >
          <ApolloProvider client={ApolloClient}>
            <MuiThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <PersistGate loading={null} persistor={persistor}>
                  <Router history={history}>
                    <Route path="/" component={RootComponentWithRoutes} />
                  </Router>
                </PersistGate>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </ApolloProvider>
        </SnackbarProvider>
      </Provider>
    );
  }
}

export default App;
