import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { setCurrentUser } from './actions';
import { User } from 'redux/CurrentUser/types';

export type CurrentUserAction = ActionType<typeof setCurrentUser>;

export type CurrentUserState = Readonly<{
  currentUser: User | null;
}>;

const initialState: CurrentUserState = {
  currentUser: null,
};

const reducer = (state: CurrentUserState = initialState, action: AnyAction) => {
  const typedAction = action as CurrentUserAction;
  switch (typedAction.type) {
    case getType(setCurrentUser):
      return {
        ...state,
        currentUser: typedAction.payload.currentUser,
      };
    default:
      return state;
  }
};

export default reducer;
