import { ActionType, getType } from 'typesafe-actions';

import { AnyAction } from 'redux';
import { setCurrentCard } from './actions';

export type CurrentCardAction = ActionType<typeof setCurrentCard>;

export type CurrentCardState = Readonly<{
  currentCard: any;
}>;

const initialState: CurrentCardState = {
  currentCard: null,
};

const reducer = (state: CurrentCardState = initialState, action: AnyAction) => {
  const typedAction = action as CurrentCardAction;
  switch (typedAction.type) {
    case getType(setCurrentCard):
      return {
        ...state,
        currentCard: typedAction.payload.currentCard,
      };
    default:
      return state;
  }
};

export default reducer;
