import { createStandardAction } from 'typesafe-actions';

export const toggleLinkFullVisibility = createStandardAction('Board/TOGGLE_LINK_FULL_VISIBILITY')();

export const startLinkingEpicToDependency = createStandardAction(
  'Board/START_LINKING_EPIC_TO_DEPENDENCY',
)<{
  element: any;
  epicSprintId: string;
  epicId: string;
  epicZoneId: string;
  epicDependencies: string[];
}>();

export const startLinkingDependency = createStandardAction('Board/START_LINKING_DEPENDENCY')<{
  element: any;
  dependencyId: string;
  dependencyZoneId: string;
  dependencySprintId: string;
  dependencyDueDate: string;
  dependencyEpics: string[];
  dependencyParentDependencies: string[];
  dependencyChildDependencies: string[];
}>();

export const stopLinkingDependencyAndEpic = createStandardAction(
  'Board/STOP_LINKING_DEPENDENCY_AND_EPIC',
)();

export const showEpicDependencyLinks = createStandardAction('Board/SHOW_EPIC_DEPENDENCY_LINKS')<{
  epicId: string;
  sprintId: string;
  dependencyIds: string[];
}>();

export const showDependencyLinks = createStandardAction('Board/SHOW_DEPENDENCY_LINKS')<{
  dependencyId: string;
  sprintId: string;
  epicIds: string[];
  dependencyParentIds: string[];
  dependencyChildIds: string[];
}>();

export const editEpicDependencyLinks = createStandardAction('Board/EDIT_EPIC_DEPENDENCY_LINKS')<{
  epicId: string;
  sprintId: string;
  epicZoneId: string;
  dependencyIds: string[];
}>();

export const editDependencyDependencyLinks = createStandardAction(
  'Board/EDIT_DEPENDENCY_DEPENDENCY_LINKS',
)<{
  dependencyId: string;
  sprintId: string;
  dependencyZoneId: string;
  dependencyChildIds: string[];
}>();

export const deleteEpicDependencyLink = createStandardAction('Board/DELETE_EPIC_DEPENDENCY_LINK')<{
  dependencyId: string;
}>();

export const deleteDependencyDependencyLink = createStandardAction(
  'Board/DELETE_DEPENDENCY_DEPENDENCY_LINK',
)<{
  dependencyChildId: string;
}>();

export const stopDependencyLinksDisplayMode = createStandardAction(
  'Board/STOP_DEPENDENCY_LINKS_DISPLAY_MODE',
)();
export const stopDependencyLinksEditMode = createStandardAction(
  'Board/STOP_DEPENDENCY_LINKS_EDIT_MODE',
)();

export default {
  toggleLinkFullVisibility,
  showEpicDependencyLinks,
  showDependencyLinks,
  editEpicDependencyLinks,
  editDependencyDependencyLinks,
  stopDependencyLinksDisplayMode,
  stopDependencyLinksEditMode,
  deleteEpicDependencyLink,
  deleteDependencyDependencyLink,
  startLinkingEpicToDependency,
  startLinkingDependency,
  stopLinkingDependencyAndEpic,
};
