import { RootState } from '../types';

export const DEFAULT_BOARD_CONFIG = {
  sprintWidth: 150,
  zonesIsClosed: {},
  sideMenuIsExpanded: false,
  sideMenuIsOpen: true,
  milestonesIsSelected: {},
};

const makeSelectConfig = propertyName => (state: RootState) => {
  const boardSlugId = state.board && state.board.board && state.board.board.slugId;
  const config = state.boardConfig.config[`boardConfig:${boardSlugId}`];

  if (!config || !config[propertyName]) {
    return DEFAULT_BOARD_CONFIG[propertyName];
  }

  return config[propertyName];
};

export const selectSprintWidth = makeSelectConfig('sprintWidth');
export const selectZonesIsClosed = makeSelectConfig('zonesIsClosed');
export const selectMilestonesIsSelected = makeSelectConfig('milestonesIsSelected');

export default {
  selectSprintWidth,
  selectZonesIsClosed,
  selectMilestonesIsSelected,
};
