import { createStandardAction } from 'typesafe-actions';
import { Board } from './types';

export const setBoard = createStandardAction('Board/SET_BOARD')<{
  board: Board | null;
}>();

export const setBoardVersion = createStandardAction('Board/SET_BOARD_VERSION')<{
  id: string;
  board: Board | null;
}>();

export const setLoading = createStandardAction('Board/SET_LOADING')<{
  loading: boolean;
}>();

export const applyBoardChanges = createStandardAction('Board/APPLY_BOARD_CHANGES')<{
  changes: any;
}>();

export const subscriptionUpdateBoard = createStandardAction('Board/SUBSCRIPTION_UPDATE_BOARD')<{
  updates: any;
}>();

export const updateBoard = createStandardAction('Board/UPDATE_BOARD')<{
  name: any;
  fn: any;
  args: any[];
  onSuccess: any;
  onError: any;
}>();

export const callAPI = createStandardAction('Board/CALL_API')<{
  name: any;
  fn: any;
  args: any[];
  onSuccess: any;
  onError: any;
}>();

export const moveEpic = createStandardAction('Board/MOVE_EPIC')<{
  zoneId: string;
  sourceSprintId: string;
  destinationSprintId: string;
  sourceIndex: number;
  destinationIndex: number;
}>();

export const moveDependency = createStandardAction('Board/MOVE_DEPENDENCY')<{
  sourceZoneId: string;
  destinationZoneId: string;
  sourceSprintId: string;
  destinationSprintId: string;
  sourceIndex: number;
  destinationIndex: number;
}>();

export const fetchBoard = createStandardAction('Board/FETCH_BOARD')<{
  slugId: string;
}>();

export const fetchBoardVersion = createStandardAction('Board/FETCH_BOARD_VERSION')<{
  boardId: string;
  versionId: string;
}>();

export const moveZone = createStandardAction('Board/MOVE_ZONE')<{
  sourceIndex: number;
  destinationIndex: number;
}>();

export const initialiseActionStatus = createStandardAction('Board/INITIALISE_ACTION')<{
  name: string;
}>();

export const updateActionStatus = createStandardAction('Board/UPDATE_ACTION')<{
  name: string;
  loading: boolean;
  error: string;
}>();

export default {
  callAPI,
  setBoard,
  fetchBoard,
  fetchBoardVersion,
  applyBoardChanges,
  moveEpic,
  moveDependency,
  moveZone,
  updateBoard,
  initialiseActionStatus,
  updateActionStatus,
  setBoardVersion,
  setLoading,
  subscriptionUpdateBoard,
};
